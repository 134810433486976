<template>
  <a-spin :spinning="pageLoadFlag">
    <div id="outDiv-box" class="outDiv" :class="{isMobile:isMobile}">
      <div class="contentDiv">
        <!--        <p>订单号：{{ orderCode  }}</p>-->
        <div class="topBox">
          <img src="./../../assets/order/Shape备份@3x.png" />
          <span>收银台</span>
        </div>
        <div class="erweima">
          <div class="jineBox">
            <span class="text">支付金额:<span class="redTxte">￥</span><span class="BigRText">{{money}}</span></span>
          </div>
          <!--        <img src="@/assets/login/erweima.jpg" alt="" />-->
          <div class="qrcode" id="qrcode" ref="qrcodeContainer"></div>
          <div class="iconBox">
            <div>
              <img src="./../../assets/order/云闪付支付@3x.png" />
              <img src="./../../assets/order/支付宝@3x.png" />
              <img src="./../../assets/order/微信@3x.png" />
            </div>
            <p>
              扫描二维码支付
            </p>
          </div>
        </div>

      </div>
    </div>
  </a-spin>
</template>
<script>
import QRCode from "qrcodejs2";
import { getPay } from "@/views/H5Pay/api";

export default {
  data() {
    return {
      isMobile: false,
      // orderCode: "",
      pageLoadFlag: false,
      money:0
    };
  },
  created() {
    document.body.classList.add("app-wrapper");
  },
  beforeDestroy() {
    document.body.classList.remove("app-wrapper");
  },
  mounted() {
    this.pageLoadFlag = true
    this.$nextTick(() => {
      $("#outDiv-box").css("font-size", (document.documentElement.clientWidth / 1920) * 100 + "px");
      const sWidth = document.documentElement.clientWidth;
      if (sWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    // this.qrcode("20220419165826da7e3ee6-8057-45");
    // 截取网址？后边的id 传给查询二维码的接口
    let url = window.location.href;
    let index = url ? url.indexOf("?") : "";
    let result = index ? url.substr(index + 1, url.length) : "";
    if (result) {
      this.getInfo(result);
    }
    // 二维码刷新
    let that = this;
    that.timer = window.setInterval(() => {
      setTimeout(function() {
        that.getInfo(result);
      }, 0);
    }, 1800000);

  },
  methods: {
    // 根据url中的截取的数据查询二维码链接和订单号
    getInfo(wurl) {
      let data = {
        qrCodeId: wurl
      };
      getPay(data).then(res => {
        if (res.data.code == 0) {
          // this.orderCode = res.data.data.orderCode;
          this.money = res.data.data.money || 0
          this.$nextTick(() => {
            // 获取接口中的二维码url  用于渲染
            let url2 = res.data.data.qrCodeUrl;
            this.qrcode(url2);
          });
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;
      }).catch(error => {
        console.log(error);
        this.pageLoadFlag = false;
      });
    },
    // 生成二维码
    qrcode(url) {
      document.getElementById("qrcode").innerHTML = "";
      let qrcode = new QRCode(this.$refs.qrcodeContainer, {
        width: 260,
        height: 260,
        padding: 30,
        text: url, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: 3
        // correctLevel：QRCode.CorrectLevel.H
      });
    }
  },
  // 离开页面 销毁timer
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    document.body.classList.remove("app-wrapper");
  }
};
</script>

<style lang="less" scoped>

// @accountBtnW: 200px;
// 媒体查询定义全局字体大小
@media screen and (min-width: 2600px) {
  // 2600 屏幕以上时定义根元素字体为1em=160px
  #outDiv-box {
    font-size: 160px !important;
  }
}

@media screen and (min-width: 2250px) and (max-width: 2600px) {
  // 2250-2600 屏幕时定义根元素字体为1em=128px
  #outDiv-box {
    font-size: 128px !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 2250px) {
  // 1920-2250 屏幕时定义根元素字体为1em=100px
  #outDiv-box {
    font-size: 100px !important;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1900px) {
  // 1800-1900 屏幕时定义根元素字体为1em=92px
  #outDiv-box {
    font-size: 92px !important;
  }
}

@media screen and (min-width: 1650px) and (max-width: 1800px) {
  // 1800-1920 屏幕时定义根元素字体为1em=80px
  #outDiv-box {
    font-size: 80px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1650px) {
  // 1400-1650 屏幕时定义根元素字体为1em=72px
  #outDiv-box {
    font-size: 72px !important;
  }
}

@media screen and (min-width: 642px) and (max-width: 1399px) {
  // 1400 以下屏幕时定义根元素字体为1em=60px
  #outDiv-box {
    font-size: 60px !important;
  }
}

@media screen and (min-width: 569px) and (max-width: 641px) {
  #outDiv-box.isMobile {
    font-size: 100px !important;
  }
}

@media screen and (min-width: 482px) and (max-width: 568px) {
  #outDiv-box.isMobile {
    font-size: 90px !important;
  }
}

@media screen and (min-width: 429px) and (max-width: 481px) {
  #outDiv-box.isMobile {
    font-size: 80px !important;
  }
}

@media screen and (min-width: 402px) and (max-width: 428px) {
  #outDiv-box.isMobile {
    font-size: 70px !important;
  }
}

@media screen and (max-width: 401px) {
  #outDiv-box.isMobile {
    font-size: 60px !important;
  }
}

@media screen and (max-width: 641px) {
  #outDiv-box.isMobile {
    // font-size: 60px !important;
    font-size: .26em;

  }
}


.outDiv {
  width: 100vw;
  height: 100vh;
  background: #f7f7f7 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .contentDiv {
    .topBox {
      position: relative;
      width: 100%;
      height: 2.2rem;
      margin-bottom: 2.2rem;

      img {
        position: absolute;
        width: 13.9rem;
        height: 2.2rem;
        left: 4rem;
      }

      span {
        position: absolute;
        left: 19.3rem;
        font-size: 2rem;
        font-weight: 500;
        color: #00AAA6;
        margin-top: -0.4rem;
      }
    }
    .erweima {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 29.6rem;
      height: 38.2rem;
      background: url("./../../assets/order/出票背景@3x.png");
      background-size: 100% 100%;

      .jineBox {
        width: 18.5rem;
        margin-top: -14rem;

        .text {
          font-size: 1.4rem;

          .redTxte {
            color: #D9161C;
            margin-left: 4px;
            font-size: 1.4rem;
          }

          .BigRText {
            color: #D9161C;
            font-size: 2.6rem;
            font-width: 500;
          }
        }
      }

      .qrcode {
        width: 17rem !important;
        height: 17rem !important
      }

      /*#qrcode>>>canvas必须有，控制生成二维码大小*/

      #qrcode /deep/ canvas {
        width: 100% !important;
        height: 100% !important;
      }

      #qrcode /deep/ img {
        width: 100% !important;
        height: 100% !important;
      }

      .iconBox {
        position: absolute;
        bottom: 3.3rem;

        div {
          height: 2.4rem;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;

          }

          img:last-child {
            margin: 0px !important;
          }
        }

        p {
          font-size: 1.4rem !important;
          font-weight: 400;
          color: #AAAAAA;
          margin-top: 0.6rem;
          line-height: 1.4rem;

        }
      }
    }
  }
}
</style>


